<template>
  <b-modal
    id="input-modal"
    ref="input-modal"
    :title="`Dữ liệu nhập ${title}`"
    size="xl"
    hide-footer
    centered
    @hide="
      $emit('update:selected', {
        text: 'Xem dữ liệu nhập chi tiết',
        value: null,
      })
    "
    dialog-class="full-width"
    style="padding-left: 0"
  >
    <b-container fluid>
      <div class="d-flex justify-content-end">
        <b-button class="mr-2">Export kết quả</b-button>
        <div class="selectRange-container">
          <v-select
            transition=""
            class="selectRange"
            v-model="selectedDate"
            :options="tableName === 'hba1c-table' ? options : optionsDate"
            :clearable="false"
            label="name"
          ></v-select>
        </div>
      </div>
      <!-- Table here -->

      <component
        v-bind:is="tableName"
        :time="selectedDate"
        :idUser="idUser"
      ></component>
      <!-- Table here -->
    </b-container>
  </b-modal>
</template>

<script>
import vSelect from 'vue-select';
export default {
  name: 'ModalInputDetail',
  components: {
    'v-select': vSelect,
    'hba1c-table': () => import('./tables/hba1c'),
    'blood-sugar': () => import('./tables/bloodSugar'),
    'blood-pressure': () => import('./tables/bloodPressure'),
    'exercise-table': () => import('./tables/exercise'),
    'feeling-table': () => import('./tables/feeling'),
    'food-table': () => import('./tables/food'),
    'weight-table': () => import('./tables/weight'),
  },
  props: {
    selected: {
      type: Object,
    },
    data: {
      type: Object,
    },
  },
  data() {
    return {
      column: [
        {
          key: 'username',
          label: 'Bệnh nhân',
          sortable: true,
        },
        {
          key: 'actionDropdown',
          label: '',
          sortable: false,
        },
        {
          key: 'age',
          label: 'Độ tuổi',
          sortable: false,
        },
        {
          key: 'gender',
          label: 'Giới tính',
          sortable: false,
        },
        {
          key: 'date',
          label: 'Thời gian tạo',
          sortable: false,
        },
        {
          key: 'country',
          label: 'Quốc gia',
          sortable: false,
        },
        {
          key: 'province',
          label: 'Tỉnh Thành',
          sortable: false,
        },
        {
          key: 'district',
          label: 'Quận',
          sortable: false,
        },
        {
          key: 'wards',
          label: 'Huyện',
          sortable: false,
        },
        {
          key: 'isActive',
          label: 'Trạng Thái',
          sortable: false,
        },
      ],
      options: [
        { name: '6 tháng gần nhất', value: 1 },
        { name: '1 năm gần nhất', value: 2 },
        { name: '2 năm gần nhất', value: 3 },
      ],
      optionsDate: [
        { name: '7 ngày gần nhất', value: 1 },
        { name: '14 ngày gần nhất', value: 2 },
        { name: '30 ngày gần nhất', value: 3 },
        { name: '90 ngày gần nhất', value: 4 },
      ],
      selectedDate: {},
      form: {},
      tableName: null,
    };
  },

  watch: {
    selected: {
      handler(newVal) {
        this.tableName = newVal.value;
        newVal.value === 'hba1c-table'
          ? (this.selectedDate = this.options[2])
          : (this.selectedDate = this.optionsDate[3]);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    title() {
      return this.selected.text;
    },
    idUser() {
      return this.data?.id;
    },
  },
};
</script>

<style lang="scss" scoped></style>
<style lang="scss">
.selectRange-container {
  width: 200px;
}
.selectRange {
  background-color: #e4e6ef;
  border-color: #e4e6ef;
  padding: 8px 13px;
  border-radius: 6px;
  .vs__dropdown-toggle {
    border: 0;
  }
}
</style>
